import React from "react";
import styled from "styled-components";

const Styled = styled.span`
  color: ${props => props.color};
`;

const Color = (props) => (
  <Styled color={props.color}>{props.children}</Styled>
);

export default Color;

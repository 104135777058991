import React from "react";
import styled from "styled-components";
import Section from "../components/section";
import Color from "../components/color";
import dave from "../images/dave_square-min.png";
import Braces from "../components/braces";
import theme from "../constants/theme";
import SECTIONS from "../constants/sections";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  @media(min-width: ${props => props.theme.screens.medium}) {
    width: ${props => props.theme.screens.medium};
  }
`;

const Image = styled.img`
  border-radius: 50%;
  height: auto;
  width: 300px;
  padding: 2rem;

  @media(min-width: ${props => props.theme.screens.medium}) {
    width: 400px;
  }
`;

const Paragraph = styled.div`
  line-height: 1.25rem;
  font-size: 1.1rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: ${props => props.theme.body.color}
`;

const Title = styled.span`
  font-size: 1.3rem;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 2.5rem;
  padding-right: 1.5rem;
  list-style: square;
  color: ${props => props.theme.body.color}
`;

const Item = styled.li`
  margin: 0;
  margin-bottom: 1rem;
`;

const AboutSection = styled.div`
  width: 100%;
`;

const SectionContent = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
`;

const About = () => (
  <Section name={SECTIONS.about.label} seo={SECTIONS.about.seo}>
    <Wrapper>
      <Content>
        <Image alt="Dave Mariano" src={dave} />

        <Paragraph>
          My name is Dave Mariano. I am a software engineer with an extreme passion for video games, technology, and writing beautiful code. For the past 7 years I have been involved in developing, managing, and shipping enterprise-level software. I’m constantly motivated to explore new worlds and take on new challenges.
        </Paragraph>

        <AboutSection>
          <SectionContent>
            <Braces headComponent={<Color color={theme.colors.neonRed}><Title>skills</Title></Color>} collapsed={true}>
              <List>
                <Item>9 years of experience developing enterprise-level software across multiple languages</Item>
                <Item>Professional experience with various LLM use cases, including breaking down intent recognition, implementing RAG workflows, query transformation, etc.</Item>
                <Item>Strong grasp of asynchronous programming through professional experience with C#, TypeScript, Node.js, Electron, and React.js</Item>
                <Item>Deep understanding of Blockchain technologies, Ethereum, and writing smart contracts with Solidity</Item>
                <Item>Passionate about writing and optimizing code for performance, testability, and scalability</Item>
                <Item>Preference for working with strongly-typed, object-oriented languages like C# and C++</Item>
                <Item>Daily, advanced use of MySQL databases, experience with NoSQL databases like MongoDB</Item>
                <Item>Knowledgeable in network programming after years of exposure in asset discovery</Item>
                <Item>Years of experience with virtualization and major cloud providers like AWS and Azure</Item>
                <Item>Familiar with CI/CD and related technologies: git, unit testing, Jenkins CI, Octopus Deploy </Item>
                <Item>Highly proficient in Agile methodology and in using management tools like Jira Cloud</Item>
                <Item>Proven leadership skills through managing a team of developers</Item>
                <Item>Ability to learn and adapt to new environments at a rapid pace</Item>
                <Item>Works strongly as an individual and with a team</Item>
                <Item>Curious about everything</Item>
              </List>
            </Braces>
          </SectionContent>
        </AboutSection>

        <AboutSection>

          <SectionContent>
            <Braces headComponent={<Color color={theme.colors.neonGreen}><Title>goals</Title></Color>} collapsed={true}>
              <Paragraph>
                Video games have always been a big part of my life, I believe them to be one of the most limitless forms of art. Games like Ocarina of Time, Majora’s Mask, Half-Life, and Resident Evil will always have a special place in my heart. I believe games like this have an important commonality, in that they each create their own convincing identity, driving towards a perfect and immersive experience. It is my dream to play a role in providing the world with experiences not unlike the ones I had as a child, and continue to have to this day.
              </Paragraph>
            </Braces>
          </SectionContent>
        </AboutSection>

        <AboutSection>

          <SectionContent>
            <Braces headComponent={<Color color={theme.colors.cyan}><Title>accomplishments</Title></Color>} collapsed={true}>
              <List>
                <Item>Implementing the SCRUM/Agile methodology in an organization when no concrete software development life cycle model was in place</Item>
                <Item>Developing a Windows application that discovers network devices with an intuitive UI, making for an uncomplicated configuration</Item>
                <Item>Refactoring and optimizing core processes to support much larger data sets</Item>
              </List>
            </Braces>
          </SectionContent>
        </AboutSection>

        <AboutSection>

          <SectionContent>
            <Braces headComponent={<Color color={theme.colors.neonPink}><Title>personal</Title></Color>} collapsed={true}>
              <Paragraph>
                I live in Toronto. I love this city. Several years ago I moved to Toronto with my girlfriend at the time. A year later we became the luckiest family alive and brought home our dog, Zero. The next year we got married just up the street from where we live. On the last day of 2021 my wife gave birth to our daughter, Ripley. The three of them are my world. My wife is an extremely talented architect and a deadly baker. Ripley is a little ball of cute furociousness. Zero is the sweetest little lady on the planet, she loves everyone and everything. She is also very talented. I have never met another dog who likes to sleep in as much as she does.
              </Paragraph>
            </Braces>
          </SectionContent>
        </AboutSection>

      </Content>
    </Wrapper>
  </Section>
);

export default About;